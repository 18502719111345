import './bootstrap';

require('./datatables');

$(function () {
    $('#datepicker').datepicker();
});

$(function () {
    const loadAjaxOptionsForElement = (selectorElement, targetElement) => {
        targetElement.find('option').remove();
        targetElement.append($('<option></option>').attr('value', null).text('Auswahl'));
        const route = selectorElement.find(':selected').data('route');
        if (route !== undefined) {
            axios.get(route).then((response) => {
                const options = [];
                if (response.data.length > 0) {
                    _.forEach(response.data, function (item) {
                        options.push(
                            $('<option></option>')
                                .attr('value', item.id)
                                .text(item.name)
                                .data('route', item.route)
                        );
                    });

                    targetElement.append(options);
                    targetElement.prop('disabled', false);
                } else {
                    $.toast({
                        'icon': 'error',
                        'text': 'Es konnten keine Kennwörter gefunden werden.'
                    });
                }
            }).catch((error) => {
                $.toast({
                    'icon': 'error',
                    'text': 'Es konnten keine Kennwörter gefunden werden.'
                });
            });
        } else {
            targetElement.prop('disabled', true);
        }
    }


    $('#js-location-selector').on('change', function (e) {
        e.preventDefault();
        loadAjaxOptionsForElement($(this), $('#js-keyword-selector'));
    });

    $('#js-keyword-selector').on('change', function (e) {
        e.preventDefault();
        loadAjaxOptionsForElement($(this), $('#js-assignment-selector'));
    });

    $('#js-assignment-selector').on('change', function (e) {
        e.preventDefault();
        loadAjaxOptionsForElement($(this), $('#js-machine-selector'));
    });

});

$(function () {
    $('.js-submit-ajax-form').each(function (index, element) {
        $(element).on('submit', function (e) {
            e.preventDefault();
            const formData = new FormData(element);

            axios.request($(element).attr('action'), {
                method: $(element).attr('method'),
                data: formData,
            }).then((response) => {
                location.reload();
            }).catch((errors) => {
                console.log(errors);
                $.toast({
                    icon: 'error',
                    text: Object.values(errors.response.data.errors).join('\n'),
                })
            });

        })
    });
})
